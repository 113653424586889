<template>
    <b-card class="mb-3">
        <b-row class="app-card-header mb-2" v-if="cOneTab == true">
            <b-col >
                <div class="app-card-profile-title">
                    {{trans('planning-information',192)}}
                </div>
            </b-col>
        </b-row>
        <b-row class="app-component-icon-edit">
            <b-dropdown variant="link"  no-caret right v-if="user_role < 3 || profile.can_user_edit">
                <template #button-content>
                    <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                </template>
                <b-dropdown-item @click="change()">{{trans('change-planning-information',260)}}</b-dropdown-item>
                <b-dropdown-item @click="changeQulification()">{{trans('change-qualifications',260)}}</b-dropdown-item>
            </b-dropdown>
        </b-row>
            <b-row class="app-card-header">
                <b-col start>
                    <p class="col-list-label">{{trans('zzp-preferences',260)}}</p>
                </b-col>
                <b-col>
                    <p class="col-form-value">{{profile.department_data.zzp_preferences  ? profile.department_data.zzp_preferences_name  : "-"}}</p>
                </b-col>
            </b-row>
            <b-row class="app-card-header">
                <b-col start>
                    <p class="col-list-label">{{trans('department-information',260)}}</p>
                </b-col>
                <b-col cols="6">
                    <p class="col-form-value">{{profile.department_data.department_information  ? profile.department_data.department_information  : "-"}}</p>
                </b-col>
            </b-row>
            <b-row class="app-card-header">
                <b-col start>
                    <p class="col-list-label">{{trans('service-information',260)}}</p>
                </b-col>
                <b-col cols="6">
                    <p class="col-form-value">{{profile.department_data.shift_information  ? profile.department_data.shift_information  : "-"}}</p>
                </b-col>
            </b-row>
            <b-row class="app-card-header">
                <b-col start>
                    <p class="col-list-label">{{trans('sh-pause',215)}}</p>
                </b-col>
                <b-col cols="6">
                    <p class="col-form-value">{{profile.department_data.pause_type == 1 ? trans('cp-break-according-to-atw-specification',192)  : trans('cp-no-break',192)}}</p>
                </b-col>
            </b-row>
            <b-row class="app-card-header">
                <b-col start>
                    <p class="col-list-label">{{trans('cp-night-shift-break',192)}}</p>
                </b-col>
                <b-col cols="6">
                    <p class="col-form-value">{{profile.department_data.night_pause == 1  ? "Ja"  : "Nee"}}</p>
                </b-col>
            </b-row>


            <b-row class="app-card-header mt-4">
                <b-col start>
                    <p class="col-list-label">{{trans('kwalifications',260)}}</p>
                </b-col>
                <b-col cols="6">
                    <p class="col-form-value">{{profile.department_data.qualification_name  ? profile.department_data.qualification_name  : "-"}}</p>
                </b-col>
            </b-row>
            

            <b-row class="app-card-header">
                <b-col start>
                    <p class="col-list-label">{{trans('target-group',260)}}</p>
                </b-col>
                <b-col cols="6">
                    <p class="col-form-value">{{profile.department_data.group_const_type_names  ? profile.department_data.group_const_type_names  : "-"}}</p>
                </b-col>
            </b-row>
        <clients-profile-department-planning-information-change :show.sync="mPlanningInformationChange.show" :args="mPlanningInformationChange.args" :result="mPlanningInformationChange.result" :return.sync="profile" />
        <clients-profile-department-qualification-change v-if="mPlanningQualificationChange.show" :show.sync="mPlanningQualificationChange.show" :args="mPlanningQualificationChange.args" :result="mPlanningQualificationChange.result" :return.sync="profile" />

    </b-card>
</template>
<script>

import ClientsProfileDepartmentPlanningInformationChange from './ClientsProfileDepartmentPlanningInformationChange.vue'
import ClientsProfileDepartmentQualificationChange from './ClientsProfileDepartmentQualificationChange.vue'

export default {
    components:{
        ClientsProfileDepartmentPlanningInformationChange,
        ClientsProfileDepartmentQualificationChange
    },

    props:{
        profile: {},
        cOneTab: {},
    },

    computed: {
      
    },

    data(){
        return{
             mPlanningInformationChange: { show: false, args: { 
                                            id_department_data_main:null,
                                            planning_information:{
                                                zzp_preferences:null,
                                                department_information:null,
                                                shift_information:null,
                                                pause_type: null,
                                                night_pause: null
                                            },
            }, result: 0, return: null },

             mPlanningQualificationChange: { show: false, args: { 
                                                        ids_qualifications_skills_group: null,
                                                        ids_group_const_type: [],
                                                        id_department_data_main: null
            }, result: 0, return: null },
            
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
        }
    },

    methods: {
        change(){
            this.mPlanningInformationChange.args.id_department_data_main = this.profile.id;
            this.mPlanningInformationChange.args.planning_information.zzp_preferences = this.profile.department_data.zzp_preferences;
            this.mPlanningInformationChange.args.planning_information.department_information = this.profile.department_data.department_information ? this.profile.department_data.department_information : "";
            this.mPlanningInformationChange.args.planning_information.shift_information = this.profile.department_data.shift_information ? this.profile.department_data.shift_information : "";
            this.mPlanningInformationChange.args.planning_information.pause_type = this.profile.department_data.pause_type;
            this.mPlanningInformationChange.args.planning_information.night_pause = this.profile.department_data.night_pause;
            this.mPlanningInformationChange.show = true;
        },

        changeQulification(){
            this.mPlanningQualificationChange.args.ids_qualifications_skills_group = this.profile.department_data.ids_qualifications_skills_group ? JSON.parse(this.profile.department_data.ids_qualifications_skills_group) : [];
            this.mPlanningQualificationChange.args.ids_group_const_type = this.profile.department_data.ids_group_const_type ? JSON.parse(this.profile.department_data.ids_group_const_type) : [];
            this.mPlanningQualificationChange.args.id_department_data_main = this.profile.id;
            this.mPlanningQualificationChange.show = true;
        }
    },
}
</script>
<style>
</style>