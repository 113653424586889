<template>
    <div>
        <b-modal ref="modal-add" size="lg" v-model="computedShow" no-close-on-backdrop :title="trans('change-qualifications',260)">
            <app-row-left-label :label="trans('kwalifications',260)" class="custom-legend-size">
                 <app-checkbox-group v-model="cArgs.ids_qualifications_skills_group">
                    <template v-slot:customData>
                        <b-row>
                            <b-col cols="6">
                                <b-form-checkbox :value="1">{{trans('cp-qualification-medication',189)}}</b-form-checkbox>
                            </b-col>
                             <b-col cols="6">
                                <b-form-checkbox :value="2">{{trans('cp-qualification-aggression',189)}}</b-form-checkbox>
                             </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6">
                                <b-form-checkbox :value="3">{{trans('cp-qualification-hoists',189)}}</b-form-checkbox>
                            </b-col>
                            <b-col cols="6">
                                <b-form-checkbox :value="4">{{trans('cp-qualification-occupational',189)}}</b-form-checkbox>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6">
                                <b-form-checkbox :value="5">{{trans('cp-qualification-adl',189)}}</b-form-checkbox>
                            </b-col>
                            <b-col cols="6">
                                <b-form-checkbox :value="6">{{trans('cp-qualification-probe',189)}}</b-form-checkbox>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6">
                                <b-form-checkbox :value="7">{{trans('cp-qualification-behavioural',189)}}</b-form-checkbox>
                            </b-col>
                        </b-row>
                    </template>
                 </app-checkbox-group>
            </app-row-left-label>
            
            <app-row-left-label :label="trans('target-group',260)" class="custom-legend-size">
                <app-checkbox-group v-model="cArgs.ids_group_const_type" v-if="!loading && items.length > 0">
                    <template v-slot:customData>
                        <b-row>
                            <b-col cols="6">
                                <b-row>
                                    <b-col cols="12" v-for="item in options_1col" :key="item.value"><b-form-checkbox :value="item.value">{{item.name}}</b-form-checkbox></b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="6">
                                <b-row>
                                    <b-col cols="12" v-for="item in options_2col" :key="item.value"><b-form-checkbox :value="item.value">{{item.name}}</b-form-checkbox></b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </template>
                </app-checkbox-group>
                <b-alert variant="warning" show class="mb-2 mt-1" v-else-if="!loading">
                    <div class="text-md-start mb-2">{{trans('cp-target-group-alert', 191)}}</div> 
                </b-alert> 
            </app-row-left-label>
            
            <template #modal-footer>
                
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary"  @click="changeQualifications" :loading="loading_data || loading">
                {{trans('save',175)}}
            </app-button>
        </template>
        </b-modal>
    </div> 
</template>

<script>
import axios from "@axios";

export default {

    props: ["show", "result", "args", "return"],

    created(){
        this.getList();
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },

        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },

    },

    watch: {
        filters: {
            handler: function(val) {
                if (this.filters.sort_by != "") {
                    this.loading = true;
                    this.getList();
                }
            },
            deep: true,
        },
        filters: {
            handler: function(val) {
                this.getList();
            },
            deep: true,
        },

    },

    data() {
        return {
            id_status_const_type: this.args.id_status_const_type,
            filters:{
                search:"",
                sort_by: "tgct.name",
                sort_desc: false,
                args:[],
            },
            loading: false,
            loading_data: false,
            items:[],
            options_1col: [],
            options_2col: [],
        };
    },


    methods: {

        selectItem(item) {          
           var index = this.items.indexOf(item);
           if (item.selected === '1') {
                item.selected = '0';
                const index = this.cArgs.ids_group_const_type.indexOf(item.value);
                if (index > -1) {
                    this.cArgs.ids_group_const_type.splice(index, 1);
                }
            } else {
                item.selected = '1';   
                this.cArgs.ids_group_const_type.push(item.value)            
            }

            this.$set(this.items, index, item);
        },

         checkSelectedItems(){
            for (var i=0; i<this.items.length; i++){
                if(this.cArgs.ids_group_const_type.includes(this.items[i].value)){
                    this.items[i].selected = '1';
                }
            }
        },

       getList: function() {
            this.loading = true;
            axios
                .post("clients/department/profile/planning-information/getQualification", {
                    filters: JSON.stringify(this.filters),
                    id_department_data_main: this.cArgs.id_department_data_main,
                })
                .then((res) => {
                    this.items = res.data;
                    this.checkSelectedItems();
                    this.setColumns();
                })
                .catch((error) => {
                    Sconsole.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        changeQualifications() {
            this.loading_data = true;
            axios
                .post("clients/department/profile/planning-information/changeQualification", {
                    id_department_data_main: this.cArgs.id_department_data_main,
                    ids_qualifications_skills_group: JSON.stringify(this.cArgs.ids_qualifications_skills_group),
                    ids_group_const_type: JSON.stringify(this.cArgs.ids_group_const_type)
                })
                .then((res) => {
                    this.cReturn.department_data = res.data;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_data = false;
                    this.$emit("update:show", false);
                });
        },

        setColumns(){
            var n = this.items.length%2 ==0 ? Math.floor(this.items.length / 2) : Math.floor(this.items.length / 2) + 1;
            this.options_1col = this.items.slice(0, n);
            this.options_2col = this.items.slice(n);
        },


        closeModal() {
            this.$emit("update:show", false);
        },

    },
};
</script>

<style scoped>
.app-local-card-size{
    min-height:300px !important; 
    max-height:600px !important
}
.app-local-icon{
    width:40px !important;
}
.app-local-dropdown{
    width:65px
}
.app-local-list-group-custom-style{
    max-height:300px !important;
    overflow-y: auto;
}
.app-local-list-group-item-custom-style{
    cursor:pointer; 
    padding:4px 4px;
}
.input-group-text {
    border: none;
}
.ps{
    height: 350px;
}
.card-body {
    padding-top: 0.5rem;
}
</style>
<style>

.custom-legend-size .col-md-4{
    flex: 0 0 28% !important;
}
</style>
