<template>
  <div v-if="profile !== null" class="app-navbar-profile-content">  
    <b-navbar sticky class="app-navbar-profile" style="top: 111px">
      <div class="app-navbar-header">
        <b-row>
          <b-col cols="8" class="app-navbar-profile-margin-4"></b-col>
          <b-col end class="app-navbar-profile-margin-30">
              <div class="d-flex justify-content-end align-items-center">
                <div class="font-weight-800 mr-4 mt-1"> {{trans('core-department-profile', 271)}} </div>
                <b-dropdown variant="link" right>
                    <template #button-content>
                        <b-icon icon="gear-fill" style="width: 17px; height: 17px;"></b-icon>
                    </template>
                      <b-dropdown-item :to="{ name: 'department-profile-history', params: { id_department_data_main: id_department_data_main }}">{{trans('history',182)}}</b-dropdown-item>
                </b-dropdown>
              </div>
            </b-col>
        </b-row>
         <b-row class="align-items-center mb-3">
            <b-col cols="8"> 
              <clients-profile-department-status-bar-component :profile.sync ="profile" />
            </b-col>
          </b-row> 
      </div>
  </b-navbar>
    <div>
          <b-row>
            <b-col md="12" lg="8" class="pr-lg-2">
                  <clients-profile-department-general-data-component class="mb-3" :profile.sync="profile" />

                <b-tabs class="mb-3" justified v-on:input="changeTab" :nav-class="cOneTab ? 'd-none' : ''">
                  <!--<b-tab title="Diensten" active> <planning-offers-department-component :profile.sync="profile" /> </b-tab>-->
                  <b-tab title="Planningsinformatie"> <clients-profile-department-planning-information-component :profile.sync="profile" :cOneTab="cOneTab"/>  </b-tab> 
                </b-tabs>

                <app-collapse accordion type="margin">
                      <b-card no-body class="app-card-client">
                          <app-collapse-item style="box-shadow:none !important;" :title="trans('cp-department-data',192)" :id="'department-information'">
                            <clients-profile-department-component :profile.sync="profile" />
                          </app-collapse-item>
                      </b-card>
                      <b-card no-body class="app-card-client">
                          <app-collapse-item style="box-shadow:none !important;" :title="trans('cp-excluded-flexworkers',192)" :id="'excluded-flexworkers'">
                            <!-- <clients-profile-department-planning-information-component :profile.sync="profile" /> -->
                            <clients-profile-department-blocked-component :profile.sync="profile" />
                          </app-collapse-item>
                      </b-card>


                </app-collapse>
            
              </b-col>

              <b-col md="12" lg="4" class="mt-3 mt-lg-0 pl-lg-2">
                <clients-profile-department-contacts-component :profile.sync="profile" class="mb-3"/>
                <issues-profile-component class="mb-3" :profile.sync="profile" type="department"/>
                <notes-component :profile.sync="profile"/>
              </b-col>
          </b-row>

        </div>
  </div>
</template>
<script>


import AppCollapse from "@core/components/AppCollapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/AppCollapse/AppCollapseItem.vue";
import axios from "@axios";

import IssuesProfileComponent from '@app/Issues/IssuesProfileComponent.vue';
import NotesComponent from '@app/Notes/NotesComponent.vue';
import ClientsProfileDepartmentGeneralDataComponent from './Department/ClientsProfileDepartmentGeneralDataComponent.vue';
import ClientsProfileDepartmentContactsComponent from './Contact/ClientsProfileDepartmentContactsComponent.vue';
import ClientsProfileDepartmentStatusBarComponent from './Department/ClientsProfileDepartmentStatusBarComponent.vue';
import ClientsProfileDepartmentComponent from './Department/ClientsProfileDepartmentComponent.vue';
import ClientsProfileDepartmentPlanningInformationComponent from './Department/ClientsProfileDepartmentPlanningInformationComponent.vue';
import ClientsProfileDepartmentBlockedComponent from './Department/ClientsProfileDepartmentBlockedComponent.vue';


//Collapse

//BTab

export default {
  components: {
    
    AppCollapse,
    AppCollapseItem,
    ClientsProfileDepartmentStatusBarComponent,
    ClientsProfileDepartmentGeneralDataComponent,
    ClientsProfileDepartmentContactsComponent,
    ClientsProfileDepartmentComponent,
    ClientsProfileDepartmentBlockedComponent,

    //Right sidebar
    IssuesProfileComponent,
    NotesComponent,
    //ClientsDepartmentProfileNotesComponent,

    //Tabs
    
    //Collapse
    ClientsProfileDepartmentPlanningInformationComponent,

  },

  created() {
    this.getData();
  },

  computed : {
        cOneTab: {
            get() {
                return true;
                /*if(this.profile.person_data.register_status == 1 && this.profile.person_data.applicant_type == 2) { 
                return true;
                } else {
                return false;
                }*/
            }
        }
  },

  data(){
      return {
      
              id_department_data_main: this.$route.params.id_department_data_main,
              profile: null,
              activeTab: 0,

          }

  },

  watch: {
      "profile.department_data_refresh": {
            handler: function(val) {
                if (val > 0) {
                    this.getPersonData();
                }                
            },
        },
  },

  methods: {
    
    getData(){
      axios
        .get("clients/department/getProfile/" + this.id_department_data_main)
        .then((res) => {
            this.profile = res.data;

            this.$root.$refs.AppNavigator.updatePath('/clients/organization/' + this.profile.department_data.id_client_data_main, '/clients/location/' + this.profile.department_data.id_location_data_main)
        })
        .catch((error) => {
            console.error(`error during request: ${error}`);
        });
    },

    getPersonData(){
      
      axios
        .get("clients/department/getPersonData/" + this.id_department_data_main)
        .then((res) => {
            if(res.data.length > 0){
              this.profile.department_data = res.data[0];  
            } 
        })
        .catch((error) => {
            console.error(`error during request: ${error}`);
        });
    },

    changeTab(e){
      if (this.activeTab !== e) {
        this.activeTab = e;
      //  if(e == 0){//contact person
      //     this.profile.contact_person.refresh ++;
      //   }else if(e == 1){//department
         
      //   }
      }
      

    },

    //showLoading: function() {
    //  this.loading = !this.loading;
    //},

    /*scrollToDocument: function(val){
      
      this.openCollapseDocument = !this.openCollapseDocument;
      this.refreshPersonalDocumentsWithType = val;

      setTimeout(() => {
          
            document.getElementById('footer').scrollIntoView({
                                                                behavior: "smooth",
                                                                block: "start"
                                                            });

                    }, 800);

    }, */


  },

  mounted() {
    
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      
      if (collapseId == 'collapse-documents' && isJustShown == true){        
          //this.profile.documents.refresh ++;
      } else if(collapseId == 'collapse-competence' && isJustShown == true) {
       
      } else if(collapseId == 'collapse-excluded-flexworkers' && isJustShown == true) {
        this.profile.blocked_person.items.refresh++;
      }

    }); 

  }
 
};
</script>
<style>
  .bg-light{
    background-color: white !important;
  }
</style>