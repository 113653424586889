<template>
    <div>
        <b-row class="align-items-center ml-1 mr-1">
            <b-col>
                <div style="font-size: 24px;">{{this.profile.department_data.department_name}}</div>
                <div style="font-size: 16px; ">{{this.trans('id-number',3)}} {{this.profile.id}}</div>
                
            </b-col>

            <b-col style="max-width:200px">
                <div style="font-size: 14px;  font-weight: 600; text-align:right">
                    <span v-bind:class="{ dotActive: this.profile.department_data.deleted == 0, dotInactive: this.profile.department_data.deleted == 1 }"> </span> {{this.profile.department_data.deleted == 0 ? this.trans('active-status',3) : this.trans('inactive',3)}} <br>
                    <span class="font-weight-300">{{this.profile.department_data.status_name}}</span>
                </div>
            </b-col>
        </b-row>
    </div>
</template>
<script>


export default {
    components: {

    },

    data(){
        return{
            loadingSkeleton: false,
        }
    },

    props:["profile"],

    created(){
        
    },

    methods:{
       
    }
};
</script>

<style scoped>
    .dotActive {
        height: 10px;
        width: 10px;
        margin-right: 6px;
        background-color: #20dc37;
        border-radius: 50%;
        display: inline-block;
    }
    .dotInactive {
        height: 10px;
        width: 10px;
        margin-right: 6px;
        background-color: red;
        border-radius: 50%;
        display: inline-block;
    }
</style>
