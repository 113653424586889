<template>
    <div>
        <b-row class="app-component-icon-edit">
            <b-dropdown variant="link"  no-caret right v-if="userRole < 3 || profile.can_user_edit">
                <template #button-content>
                    <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                </template>
                <b-dropdown-item @click="change()">{{trans('cp-change-department-information',192)}}</b-dropdown-item>
                <b-dropdown-item @click="changeCAO()" v-if="this.userRole == 1 || this.userRole == 2">{{trans('settings-change-cao',167)}}</b-dropdown-item>
            </b-dropdown>
        </b-row>

        <b-row class="app-card-header">
            <b-col start>
                <p class="col-list-label">{{trans('street',182)}}</p>
                <p class="col-list-label">{{trans('house-number',182)}}</p>
                <p class="col-list-label">{{trans('cw-house-number-addition',194)}}</p>
                <p class="col-list-label">{{trans('postcode',182)}}</p>
                <p class="col-list-label mb-2">{{trans('town',192)}}</p>
                <p class="col-list-label">{{trans('email',182)}}</p>
                <p class="col-list-label mb-2">{{trans('phone-number',182)}}</p>
                <p class="col-list-label ">{{trans('cp-alliases',192)}}</p>
                <p class="col-list-label ">{{trans('cp-cost-center',192)}}</p>
                <p class="col-list-label mb-2">{{trans('cao',182)}}</p>
                 
            </b-col>
            <b-col>
                <p class="col-form-value">{{profile.department_data.street  ? profile.department_data.street : '-'}}</p>
                <p class="col-form-value">{{profile.department_data.house_number ? profile.department_data.house_number : '-'}}</p>
                <p class="col-form-value">{{profile.department_data.number_additional ? profile.department_data.number_additional : '-'}}</p>
                <p class="col-form-value">{{profile.department_data.post_code ? profile.department_data.post_code : '-'}}</p>
                <p class="col-form-value mb-2">{{profile.department_data.city ? profile.department_data.city : '-'}}</p>

                 <p class="col-form-value">
                    <b-link v-if="profile.department_data.email" :href="'mailto:' + profile.department_data.email">{{profile.department_data.email}}</b-link>
                    <span v-if="!profile.department_data.email">-</span>
                </p>
                <p class="col-form-value mb-2">
                    <b-link v-if="profile.department_data.phone_number" :href="'tel:' + phone">{{phone}}</b-link>
                    <span v-if="!profile.department_data.phone_number">-</span>
                </p>
                
                <p class="col-form-value">{{profile.department_data.name_alias ? profile.department_data.name_alias : '-'}}</p>
                <p class="col-form-value">{{profile.department_data.costplace_number ? profile.department_data.costplace_number : '-'}}</p>
                <p class="col-form-value mb-2">{{profile.department_data.section_name  ? profile.department_data.section_name : '-'}}</p>
               
            </b-col>
        </b-row>
        <clients-profile-department-change :show.sync="mDepartmentChange.show" :args="mDepartmentChange.args" :result="mDepartmentChange.result" :return.sync="profile" />
        <clients-profile-cao-change :show.sync="mCaoChange.show" :args="mCaoChange.args" :result="mCaoChange.result" :return.sync="profile.department_data" />
    </div>
</template>
<script>

import ClientsProfileDepartmentChange from './ClientsProfileDepartmentChange.vue'
import ClientsProfileCaoChange from '@app/Clients/Profile/CAO/ClientsProfileCaoChange.vue';

export default {
    components:{
        ClientsProfileDepartmentChange,
        ClientsProfileCaoChange,
    },

    props:{
        profile: {}
    },

    created(){
        this.userRole = JSON.parse(localStorage.getItem('user')).id_user_const_role;
    },

    computed: {
        phone: function () {
            if (this.profile.department_data.phone_number !== null && this.profile.department_data.phone_prefix == '+31') {
                return 0 + this.profile.department_data.phone_number.replace(/^(.{1})(.{3})(.{3})(.*)$/, "$1 $2 $3 $4");
            } else {
                return this.profile.department_data.phone_prefix + " " + this.profile.department_data.phone_number;
            }
        }
    },

    data(){
        return{
            userRole:null,  
            mDepartmentChange: { show: false, 
                                        args: { 
                                            department_data:{
                                                id_department_data_main: null,
                                                name: null,
                                                department_short_name: null,
                                                costplace_number: null,
                                                organization_address: null,
                                                external_id: null,
                                                name_alias:null,
                                                email:null,
                                                longitude: null,
                                                latitude: null,
                                                phone_prefix: null,
                                                phone_number: null,
                                                list_type: null,
                                                section: {
                                                    name: null,
                                                    value: null
                                                }
                                            },
                                            id_location_data_main: null

            }, result: 0, return: null },
            mCaoChange: { show: false, 
                                          args: { 
                                            profile_id: null,
                                            list_type:null,
                                            section: null,
            }, result: 0, return: null }
        }
    },

    methods: {
        change(){
            this.mDepartmentChange.args.department_data.id_department_data_main = this.profile.id;
            this.mDepartmentChange.args.department_data.name = this.profile.department_data.department_name;
            this.mDepartmentChange.args.department_data.department_short_name = this.profile.department_data.department_short_name;
            this.mDepartmentChange.args.department_data.costplace_number = this.profile.department_data.costplace_number;
            this.mDepartmentChange.args.id_location_data_main = this.profile.department_data.id_location_data_main;
            this.mDepartmentChange.args.department_data.email = this.profile.department_data.email;
            this.mDepartmentChange.args.department_data.phone_prefix = this.profile.department_data.phone_prefix? this.profile.department_data.phone_prefix : '+31';
            this.mDepartmentChange.args.department_data.phone_number= this.profile.department_data.phone_number;
            this.mDepartmentChange.args.department_data.longitude = this.profile.department_data.longitude;
            this.mDepartmentChange.args.department_data.latitude = this.profile.department_data.latitude;
            this.mDepartmentChange.args.department_data.external_id = this.profile.department_data.external_id;
            this.mDepartmentChange.args.department_data.name_alias = JSON.parse(JSON.stringify(this.profile.department_data.name_alias != null ? this.profile.department_data.name_alias.split(',') : []));
            this.mDepartmentChange.args.list_type= this.profile.type;
            this.mDepartmentChange.args.filters = this.filters;
            this.mDepartmentChange.show = true;
        },

        changeCAO(){
            this.mCaoChange.args.profile_id = this.profile.id;
            this.mCaoChange.args.section = this.profile.department_data.section_name != null ? {name: this.profile.department_data.section_name, value: this.profile.department_data.id_cao_data_main} : null;
            this.mCaoChange.args.list_type= this.profile.type;
            this.mCaoChange.show = true;
        }
    },
}
</script>
<style>
</style>