<template> 
    <b-modal ref="modal-client-profile-structure-location-change" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('cp-scheduling-information-change',192)">
        
        <ValidationObserver ref="form" >
            <b-form @submit.prevent="onSubmit">
                <b-row>
                    <b-col>   
                        <app-row-left-label :label="trans('zzp-preferences',260)">
                            <app-select v-model="cArgs.planning_information.zzp_preferences" reduceValue  :options="zzp_preferences_statuses" :clearable="false" :validatorName="trans('zzp-preferences',260)" validatorRules="required" />
                        </app-row-left-label>   

                        <app-row-left-label :label="trans('department-information',260)">
                            <app-memo id="textarea-default" rows="5" v-model="cArgs.planning_information.department_information" :lettersCounter="true" :maxLength="500" />  
                        </app-row-left-label>   

                        <app-row-left-label :label="trans('service-information',260)">
                            <app-memo id="textarea-default" rows="5" v-model="cArgs.planning_information.shift_information" :lettersCounter="true" :maxLength="500" />  
                        </app-row-left-label>  
      
                        <app-row-left-label :label="trans('sh-pause',215)">
                            <app-radio-group v-model="cArgs.planning_information.pause_type" :options="options_pause" :validatorName="trans('sh-pause',215)" validatorRules="required" :validatorCustomMessage="{ required: trans('field-required-error',180) }" />
                        </app-row-left-label>

                        <app-row-left-label :label="trans('cp-night-shift-break',192)">
                            <app-radio-group v-model="cArgs.planning_information.night_pause" :options="options_night_pause" :validatorName="trans('cp-night-shift-break',192)" validatorRules="required" :validatorCustomMessage="{ required: trans('field-required-error',180) }" />
                        </app-row-left-label>

                    </b-col>
                </b-row>
            </b-form>
        </ValidationObserver> 
        
        <template #modal-footer>
            <app-button @click="closeModal" type="cancel">{{trans('cancel',175)}}</app-button>
            <app-button @click="locationChange" type="primary" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
    
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";

export default {
    components: {
        ValidationObserver
    },

    props: ["show", "result", "args", "return"],


    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },

        cReturn: {
            get() { return this.return },
            set(value) {this.$emit('update:return', value) }, 
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },
    },

    data() {
        return {
            loading: false,
            zzp_preferences_statuses: [ 
                { value: 1, name: this.trans('cp-zzp-allowed',192) }, 
                { value: 2, name: this.trans('cp-no-zzp',192) },
                { value: 3, name: this.trans('cp-zzp-only',192) }],
            options_night_pause: [
                { value: 1, text: this.trans('yes-option',3) },
                { value: 0, text: this.trans('no-option',3) },
            ],

            options_pause: [
                { value: 1, text: this.trans('cp-break-according-to-atw-specification',192) },
                { value: 0, text: this.trans('cp-no-break',192) },
            ]
        };
    },

    methods: {

        async locationChange() {
            this.$refs.form.validate().then((result) => {
                if (!result) {            
                    return false;
                }
                    
                this.loading = true;

                 axios
                    .post("clients/department/profile/planning-information/change", {
                        id_department_data_main: this.cArgs.id_department_data_main,
                        planning_information: JSON.stringify(this.cArgs.planning_information),
                    })
                    .then((res) => {
                        this.cReturn.department_data = res.data;                        
                        this.$emit("update:result", 1);
                        this.$emit("update:show", false);                        
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    })
                    .finally(() => {
                        this.loading = false;
                    }); 
    
            })
                
        },

        closeModal() {
            this.$emit("update:result", -1);
            this.$emit("update:show", false);
        },

    },
};
</script>
<style scoped>

</style>

