<template>
    <b-modal ref="modal-client-department-profile-blocked-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('cp-exlude-flex-worker',192)">
        
        <b-card style="min-height:300px !important; max-height:600px !important">
            <div class="d-flex align-content-center justify-content-between w-100">
                <b-input-group class="input-group-merge" >

                    <b-input-group-prepend is-text>
                        <b-icon icon="search" class="text-muted" v-if="!loading_search"></b-icon>      
                        <b-spinner class="align-middle" small v-if="loading_search"></b-spinner>  
                    </b-input-group-prepend >
                    <b-form-input class="pl-2 mt-2" autofocus :placeholder="trans('search',3)" v-model="search" :lazy="true" style="border:none !important"/>
                    <b-input-group-append is-text v-if="search.length > 0">
                        <b-icon icon="x-circle-fill" class="text-muted" style="margin-top:10px; cursor: pointer; outline: none !important;" @click="search = ''"></b-icon>
                    </b-input-group-append>
                </b-input-group>
            </div>
            <hr class="app-search-modal-separator">

            <b-list-group flush class="app-local-list-group-custom-style" :style="'max-height:250px; min-height:250px; overflow-y: scroll;'">
                <b-list-group-item class="d-flex  align-items-center app-local-list-group-item-custom-style" action v-for="item in items" :key="item.id_person_data_main" tabindex="0" @click.prevent="selectItem($event, item)">
                    <b-row class="w-100">
                        <b-col start>
                            <b-form-checkbox v-model="item.checked" value="1" unchecked-value="0" size="sm" :class="item.checked == 1 ? 'custom-checkbox-checked' : ''">
                                <span class="app-documents-item">{{item.name}}</span>
                            </b-form-checkbox>
                        </b-col>
                    </b-row>
                </b-list-group-item>
            </b-list-group>
        </b-card>

        <ValidationObserver ref="form" >
            <app-row-left-label :label="trans('date-to',182)" class="mt-3">
                <app-date v-model="date_to" :disabledDatesPass="1" :disabledDatesFuture="365" />
            </app-row-left-label>

            <app-row-left-label :label="trans('description',182)">
                <app-memo v-model="description" :maxLength="500" :lettersCounter="true" />
            </app-row-left-label>
        </ValidationObserver>
        <template #modal-footer>
            <app-button @click="closeModal" type="cancel">{{trans('cancel',175)}}</app-button>
            <app-button @click="addBlockedPerson" type="primary" :disabled="selected_id == null" :loading="loading">
                {{trans('add',175)}}
            </app-button>
        </template>

    </b-modal>
</template>
<script>

import axios from "@axios";
import moment from "moment";
import { date_block_past } from "@validations";
import { ValidationObserver } from "vee-validate";

export default {

    components: {
        ValidationObserver
    },

    props: ["result", "args", "show", "return"],

    created(){

    },

    watch: {
        search:{
            handler: function(){
                if(this.search == ''){
                    this.items = [];
                    this.selected_id = null;
                }
                else{
                    this.getFlexworkerList();
                }
            }
        }
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set(value) { this.$emit('update:show',value) }
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },

         cReturn: {
             get() { return this.return },
             set(value) { this.$emit('update:return', value) }
         }
    },

    data(){
        return {
            loading: false,
            loading_search: false,
            search: '',
            items: [],
            date_to: null,
            selected_id: null,
            description: '',
        }
    },  

    methods: {
        addBlockedPerson(){

            this.$refs.form.validate().then((result) => {
                if (!result) {            
                    return false;
                }

            this.loading = true;

            axios
                .post('clients/department/profile/blocked-person/addBlockedPerson', {
                    filters: JSON.stringify(this.cArgs.filters),
                    id_person_data_main: this.selected_id,
                    id_department_data_main: this.args.id_department_data_main,
                    date_to: this.date_to,
                    description: this.description,
                    id_location_data_main: this.args.id_location_data_main,
                    id_client_data_main: this.args.id_client_data_main,
                })
                .then((res) => {
                    this.cReturn.blocked_person.items = res.data.items;
                    this.$emit('update:result', 1);
                })
                .finally(() => {
                    this.cArgs.filters.search = '';
                    this.computedShow = false;
                });
                
            });

        },

        selectItem($event, item){
            if(item.checked == "1"){
                this.items.forEach(element => {
                element.checked = "0";
                this.selected_id = null;
            });
            }
            else{
            this.items.forEach(element => {
                element.checked = "0";
            });
            item.checked = "1";
            this.selected_id = item.id_person_data_main;
            }
        },

        getFlexworkerList(){
            this.loading_search = true;

            axios
                .post('clients/department/profile/blocked-person/getFlexworkerList', {
                    search: this.search,
                    id_department_data_main: this.args.id_department_data_main,
                })
                .then((res) => {
                    this.items = res.data;
                })
                .catch((error) => {
                    console.error(`error during request ${error}`);
                })
                .finally(() => {
                    this.loading_search = false;
                })
        },

        closeModal(){
            this.computedShow = false;
        }
    }

    
}
</script>
<style scoped>

.input-group-text {
    border: none;
}

.app-local-list-group-custom-style{
    overflow-y: auto;
}

.app-local-list-group-item-custom-style{
    cursor:pointer; 
    padding:4px 4px;
}

</style>