<template>
    <div>
        <b-overlay :show="loading_list" :opacity="0.0">
            <app-view-table :isTitle="false" :customColumns="custom_columns" :isSelectable="false" :loading="loading_list" :isTitleTotal="false" :fields.sync="fields" :filters="filters" :isMenuColumn="user_role < 3 || profile.can_user_edit" :items="cProfile.blocked_person.items" :isSearch="true" :canUserEdit="false">

                <template v-slot:menu-items="cell">
                    <b-dropdown-item @click="changeBlockedPerson(cell.data.item)">{{trans('change',175)}}</b-dropdown-item>
                    <b-dropdown-item @click="deleteBlockedPerson(cell.data.item)">{{trans('remove',175)}}</b-dropdown-item>
                </template>

                <template v-slot:buttons>
                    <app-button type="primary" @click="addBlockedPerson()" v-if="user_role < 3 || profile.can_user_edit">
                        <span>{{trans('cp-add-flex-worker',189)}}</span>
                    </app-button>
                </template>

                <template v-slot:date_to="cell">
                    <span v-if="cell.data.item.date_to != null">{{cell.data.item.date_to}}</span>
                    <span v-else>-</span>
                </template>

                <template v-slot:description="cell">
                    <span v-if="cell.data.item.decription != null && cell.data.item.description.length > 10" v-b-tooltip.hover.top :title="trans('description',182)">{{ cell.data.item.description.substring(0,10) }} ...</span>
                    <span v-else> {{cell.data.item.description}} </span>
                </template>
                
            </app-view-table>
            <template #overlay>
                <div></div>
            </template>
        </b-overlay>
        <clients-department-profile-blocked-add v-if="mAdd.show" :args.sync="mAdd.args" :result.sync="mAdd.result" :show.sync="mAdd.show" :return.sync="profile" />
        <clients-department-profile-blocked-change v-if="mChange.show" :show.sync="mChange.show" :args.sync="mChange.args" :result.sync="mChange.result" :return.sync="profile" />
    </div>
</template>
<script>

import axios from "@axios";
import ClientsDepartmentProfileBlockedAdd from './ClientsProfileDepartmentBlockedAdd.vue';
import ClientsDepartmentProfileBlockedChange from './ClientsProfileDepartmentBlockedChange.vue';
import AppAlerts from '@core/scripts/AppAlerts';

export default {
    components: {
        ClientsDepartmentProfileBlockedAdd,
        ClientsDepartmentProfileBlockedChange,
    },

    props: ["profile"],

    mounted() {
        this.onCreateThis();
    },

    watch: {
        "profile.blocked_person.items.refresh": {
            handler: function(value) {
                if (value > 0 && !this.loading_list) {
                    this.getList();
                } 
            }
        }
    },

    computed: {
        cProfile: {
            get() { return this.profile },
            set(value) { this.$emit('update:profile', value) }
        }
    },

    data() {
        return {
            loading_list: false,
            alert_class: new AppAlerts(),
            custom_columns: ['description', 'date_to'],
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                filtering: {                        
                    
                },
                page: 1,
                per_page: 5,
                search: "",
                changed: 0
            },

            fields: [
                { key: "person_name", label:this.trans('flexworker',182), thStyle: { "min-width": "200px", "max-width": "250px" }, visible: true, },
                { key: "description", label: this.trans('description',182),  thStyle: { "min-width": "200px", width:"100%"  },visible: true, },
                { key: "format_date_to", label: this.trans('date-to',182),  thStyle: { "min-width": "150px", "max-width": "150px" }, visible: true, },
                { key: "source_name", label: 'Bron',  thStyle: { "min-width": "150px", "max-width": "150px" }, visible: true, }
            ],
            mAdd: { args: { 
                id_department_data_main: null,
                id_location_data_main: null,
                id_client_data_main: null,
                filters: null}, result: 0, return: null, show: false },
            mChange: { args: { 
                id_department_data_main: null,
                id_location_data_main: null,
                id_client_data_main: null,
                id_department_data_blocked: null, 
                date_to: null,
                description: null,
                filters: null
                }, result: 0, return: null, show: false },
                user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
        }
    },

    methods: {
        getList(){

            this.loading_list = true;

            axios
                .post('clients/department/profile/blocked-person/getList', {
                    filters: JSON.stringify(this.filters),
                    id_department_data_main: this.profile.id,
                    id_location_data_main: this.profile.department_data.id_location_data_main,
                    id_client_data_main: this.profile.department_data.id_client_data_main,
                })
                .then((res) => {
                    this.cProfile.blocked_person.items = res.data.items;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                })

        },

        addBlockedPerson(){
            this.mAdd.args.id_department_data_main = this.profile.id;
            this.mAdd.args.id_location_data_main = this.profile.department_data.id_location_data_main;
            this.mAdd.args.id_client_data_main = this.profile.department_data.id_client_data_main;
            this.mAdd.args.filters = this.filters;
            this.mAdd.show = true;
        },

        changeBlockedPerson(item){
            this.mChange.args.id_department_data_main = this.profile.id;
            this.mChange.args.id_location_data_main = this.profile.department_data.id_location_data_main;
            this.mChange.args.id_client_data_main = this.profile.department_data.id_client_data_main;
            this.mChange.args.id_department_data_blocked = item.id;
            this.mChange.args.date_to = item.format_date_to;
            this.mChange.args.description = item.description ? item.description : '';
            this.mChange.args.filters = this.filters;
            this.mChange.show = true;
        },

        deleteBlockedPerson(item){
            this.alert_class.openAlertConfirmation(this.trans('cp-remove-exclusion-confirmation',191)).then(res => {
                if(res == true) {

                    this.loading_list = true;
                    axios   
                        .post('clients/department/profile/blocked-person/deleteBlockedPerson', {
                            filters: JSON.stringify(this.filters),
                            id_department_data_main: this.profile.id,
                            id_department_data_blocked: item.id,
                            id_location_data_main: this.profile.department_data.id_location_data_main,
                            id_client_data_main: this.profile.department_data.id_client_data_main,
                        })
                        .then((res) => {
                            this.cProfile.blocked_person.items = res.data.items;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.loading_list = false;
                        })

                }
            });

        },

        onCreateThis() {  

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                   this.getList();
                }   
            });

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loadingList) {
                        this.getList();
                } 
            });

            this.$watch('filters.changed',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.filters.page = 1;
                    this.getList();
                } 
            });
        },
    }
}
</script>
<style>

</style>